.App {
  text-align: center;
  background-color: #282c34;
}

.App-header {
  background-color: #282c34;
  height: 100px;
  display: flex;
  align-items: center;
  margin-right: 20%;
  justify-content: flex-end;
  font-size: 28px;
  color: white;
}

.image-header {
  margin-left: 80px;
  height: 70px;
  width: 200px;
}

.bell-header-container {
  height: 100px;
  position: relative;
  margin-right: 250px;
}

.bell-header {
  position: absolute;
  margin-right: 80px;
  height: 180px;
  transform: scaleX(-1);
}

.flex-container {
  display: flex;
  align-items: center;
}

.flex-container-2 {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px 0;
}

.App-add-button {
  width: 150px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: white;
  margin-right: 20px;
  margin-left: 20px;
  color: black;
  align-self: center;
}
.App-add-button:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.App-add-button:active {
  background-color: rgba(255, 255, 255, 0.9);;
  box-shadow: 0 2px rgb(37, 211, 102);
  transform: translateY(4px);
}

.first-text {
  margin-left: 5px;
  font-size: 22px;
  font-weight: bold;
}

.App-body {
  background-color: #282c34;
  color: white;
  height: calc(100vh - 100px);
}

.App-form {
  display: flex;
  margin: 0 20%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

input,
select {
  margin-bottom: 20px;
  width: 320px;
  height: 30px;
  border-radius: 5px;
  outline: none;
  direction: rtl;
  border: none;
}

textarea {
  width: 300px;
  height: 80px;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  direction: rtl;
  border: none;
  resize: none;
}

.App-form-button {
  width: 100%;
  height: 50px;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: rgb(37, 211, 102);
  color: white;
}

.App-form-button:hover {
  background-color: rgba(37, 211, 102, 0.9);
}

.App-form-button:active {
  background-color: rgb(37, 211, 102);
  box-shadow: 0 2px #666;
  transform: translateY(4px);
}

.app-form-button-text {
  font-size: 18px;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.templates {
  align-self: center;
  margin-bottom: 0;
}


@media (max-width: 800px) {
  .App-form {
    width: 95%;
  }
  input,
  select {
    width: 300px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
